var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentInstanceRunningApps.length
    ? _c(
        "div",
        { staticClass: "shepherd-app-sidebar" },
        [
          _c("v-divider"),
          _vm._l(_vm.currentInstanceRunningApps, function (app) {
            return _c(
              "div",
              { key: app.sessionId },
              [_c("ApplicationMenuItem", { attrs: { appData: app } })],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }